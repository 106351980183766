import { environment } from './../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { TemplateService } from './../template.service';
import { HompageCarouselService} from './../homepage-carousel.service' ;
import { FormBuilder, Validators, FormGroup, FormArray, ReactiveFormsModule} from '@angular/forms'

@Component({
  selector: 'app-carousel-dashboard',
  templateUrl: './carousel-dashboard.component.html',
  styleUrls: ['./carousel-dashboard.component.css']
})
export class CarouselDashboardComponent implements OnInit {

  templates = [];
  carousel = [];
  formValue!: FormGroup;

  constructor(public homecarouselservice: HompageCarouselService, public templateservice: TemplateService, private formBuilder:FormBuilder) { }

  ngOnInit(): void {
    this.getCarouselList()
    let tempArr = [];
    this.templateservice.init(environment).getTemplates().subscribe((res)=>{
      res.map(data => {
        tempArr.push(data['id'])
        this.templates.push(data)
      })
    })
    this.formValue = this.formBuilder.group({
      image: [''],
      primary_text: [''],
      secondary_text: [''],
      helper_text_1: [''],
      helper_text_2: [''],
      template: tempArr,
    })
  }

  postCarouselDetails(){
    this.homecarouselservice.init(environment).storeCarousel(this.formValue.value).subscribe((res)=>{
      let ref = document.getElementById('cancel')
      ref?.click()
      this.formValue.reset();
      this.carousel = [];
      this.getCarouselList()
    })
  }

  getCarouselList(){
    this.homecarouselservice.init(environment).getCarousel().subscribe((response)=>{
      response.map((data)=>{
        console.log(data)
        this.carousel.push(data)
      })
    })
  }

}
