import { HomepageComponent } from './homepage/homepage.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CarouselDashboardComponent } from './carousel-dashboard/carousel-dashboard.component'
import { ArticleComponent } from './sharedComponent/components/article/article.component';

const routes: Routes = [
  { path: '', component: HomepageComponent },
  { path: 'carousel-dashboard', component: CarouselDashboardComponent },
  { path: 'vision', component: ArticleComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
  }),
  RouterModule.forChild(routes)
],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
