<nav class="navbar navbar-light bg-primary">
    <div class="container">
        <h1 class=" text-white"> Carousel Editor</h1>
        <div class="d-flex">
            <button type="button" class="btn btn-sm btn-success" data-bs-toggle="modal" data-bs-target="#exampleModal">
                Add New Carousel
            </button>
        </div>
    </div>
</nav>
<div class="container">
    <table class="table mt-3">
        <thead>
            <tr>
                <th scope="col">Images</th>
                <th scope="col">Primary Text</th>
                <th scope="col">Secondary Text</th>
                <th scope="col">Helper Text 1</th>
                <th scope="col">Helper Text 2</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
                <tr *ngFor="let datum of carousel">
                    <td >
                        <img src="{{datum.image_json.images[0]}}" alt="" height="100px" style="object-fit: contain;">
                    </td>
                    <td class="ellipsis">
                        {{datum.primary_text}}
                    </td>
                    <td class="ellipsis">
                        {{datum.secondary_text}}
                    </td>
                    <td *ngIf="datum?.helper_text"  class="ellipsis">
                        {{datum.helper_text[0]}}
                    </td>
                    <td *ngIf="!datum?.helper_text"  class="ellipsis">
                        
                    </td>
                    <td *ngIf="datum?.helper_text"  class="ellipsis">
                        {{datum.helper_text[1]}}
                    </td>
                    <td *ngIf="!datum?.helper_text"  class="ellipsis">
                        
                    </td>
                <td>
                    <button class="btn btn-sm btn-primary" value="{{datum?.id}}">Edit</button>
                    <button class="btn btn-sm btn-danger mx-2">Delete</button>
                </td>
            </tr>
        </tbody>
    </table>
      
      <!-- Modal -->
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable" style="height: 10s0vh;">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Carousel Details</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formValue">
                    <select class="form-select" aria-label="Default select example" class="form-control" id="template" formControlName="template">
                        <option selected>Select Template</option>
                        <option *ngFor="let tmplt of templates">
                            {{tmplt['id']}}
                        </option>
                      </select>
                    <div class="mb-3">
                      <label for="image" class="form-label">Image</label>
                      <input type="string" class="form-control" id="image" formControlName="image">
                    </div>
                    <div class="mb-3">
                      <label for="primary_text" class="form-label">Primary Text</label>
                      <input type="string" class="form-control" id="primary_text" formControlName="primary_text">
                    </div>
                    <div class="mb-3">
                        <label for="secondary_text" class="form-label">Secondary Text</label>
                        <input type="string" class="form-control" id="secondary_text" formControlName="secondary_text">
                    </div>
                    <div class="mb-3">
                        <label for="helper_text" class="form-label">Helper Text 1</label>
                        <input type="string" class="form-control" id="helper_text_1" formControlName="helper_text_1">
                    </div>
                    <div class="mb-3">
                        <label for="helper_text" class="form-label">Helper Text 2</label>
                        <input type="string" class="form-control" id="helper_text_2" formControlName="helper_text_2">
                    </div>
                  </form>
            </div>
            <div class="modal-footer">
              <button type="button" id="cancel" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="submit" class="btn btn-primary" (click)="postCarouselDetails()">Add</button>
            </div>
          </div>
        </div>
      </div>
</div>