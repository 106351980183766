<div *ngIf="!mobile; else mobileView">
    <owl-carousel-o [options]="HomepageCarouselOptions">
      <ng-container *ngFor="let slide of carouselArr">
          <ng-template  *ngIf="slide.template_id==1" carouselSlide [id]="slide.id">
              <img [src]="slide.image_json.images[0]" style="height: 90vh;object-fit: contain;">
          </ng-template>
          <ng-template  *ngIf="slide.template_id==2" carouselSlide [id]="slide.id">
              <div class="container pt-2">
                <div class="pt-5">
                  <div class="t2-img-container">
                    <img class="mx-auto t2-img" [src]="slide.image_json.images[0]" [alt]="slide.primary_text" [title]="slide.primary_text">
                  </div>
                </div>
                <div class="row justify-content-center" >
                  <div class="t2 primary-text">
                    {{slide.primary_text}}
                  </div>
                </div>
                <div class="row justify-content-center mt-3">
                  <div class="t2 secondary-text">
                    {{slide.secondary_text}}
                  </div>
                </div>
              </div>
          </ng-template>
          <ng-template  *ngIf="slide.template_id==3" carouselSlide [id]="slide.id">
            <div class="container">
              <div class="row">
                <div class="col-md-4">
                  <div class="t3-text-container">
                    <div class="t3 primary-text">
                      {{slide.primary_text}}
                    </div>
                    <div class="t3 secondary-text mt-4">
                    {{slide.secondary_text}}
                    </div>
                  </div>
                </div>
                <div class="col-md-8 m-auto">
                  <div style="height: 80vh;" class="m-auto">
                    <img class="t3-img" [src]="slide.image_json.images[0]" [alt]="slide.primary_text" [title]="slide.primary_text">
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template  *ngIf="slide.template_id==4" carouselSlide [id]="slide.id">
            <div class="container">
              <div class="row justify-content-center" style="height: 90vh;">
                <div class="col-md-6" >
                  <div class="p-5">
                    <img class="t3-img" [src]="slide.image_json.images[0]" [alt]="slide.primary_text" [title]="slide.primary_text" class="rounded">
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="t4-text-container">
                    <div class="t4 helper-text-1">
                      {{slide.helper_text_1}}
                    </div>
                    <div class="t4 helper-text-2">
                      {{slide.helper_text_2}}
                    </div>
                    <div class="t3 primary-text">
                      {{slide.primary_text}}
                    </div>
                    <div class="t3 secondary-text mt-4">
                    {{slide.secondary_text}}
                    </div>
                    <div class="pt-3">
                      <form [formGroup]="userEmail" (ngSubmit)="onFormSubmit(userEmail)">
                        <div class="form-group">
                          <div><h6> <span class="font-weight-normal">We will email you on</span></h6></div>
                          <div *ngIf="isValidFormSubmitted && userEmailId"><h6> {{userEmailId}} </h6></div>
                          <input *ngIf="!isValidFormSubmitted" [class.is-invalid]="email_id.invalid && email_id.touched" type="email" class="form-control" placeholder="Please enter your email address"
                            formControlName="email_id" />
                            <button *ngIf="!isValidFormSubmitted" class="submit-button"><svg class="arrow-design" viewBox="0 0 24 24">
                              <path fill="currentColor" d="M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M6,13H14L10.5,16.5L11.92,17.92L17.84,12L11.92,6.08L10.5,7.5L14,11H6V13Z" />
                            </svg></button>
                          <div *ngIf="(email_id.invalid && email_id.touched) || email_id.dirty">
                            <small *ngIf="email_id.errors?.required" class="text-danger">Primary email is required</small>
                            <small *ngIf="email_id.errors?.pattern" class="text-danger">Please provide a valid email address</small>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div>
                      <a href="" target="_blank">
                        <img src="./assets/FB Icon.png" class="icon-img d-inline" />
                        <h6 class="d-inline pl-1 text-blue-colour">@osianamaworld</h6>
                      </a>
                    </div>
                    <div class="pt-3">
                      <a href="https://www.instagram.com/osianamaworld/" target="_blank">
                        <img src="./assets/IG Icon.png" class="icon-img d-inline" />
                        <h6 class="d-inline pl-1 text-blue-colour">@osianamaworld</h6>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">

                </div>
              </div>
            </div>
          </ng-template>
          <ng-template  *ngIf="slide.template_id==5" carouselSlide [id]="slide.id">
            <div class="container">
              <div class="row t5-container">
                <div class="row primary-text justify-content-center">
                  {{slide.primary_text}}
                </div>
                <div class="row justify-content-center secondary-text mt-4">
                {{slide.secondary_text}}
                </div>
              </div>
              <div class="row">
                <div class="t5-img-container">
                  <img class="t5-img" [src]="slide.image_json.images[0]" [alt]="slide.primary_text" [title]="slide.primary_text">
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template  *ngIf="slide.template_id==6" carouselSlide [id]="slide.id">
            <div class="container">
              <div class="row">
                <div class="col-md-8 m-auto px-0" >
                  <div style="height: 82vh;">
                    <img class="t6-img" [src]="slide.image_json.images[0]" [alt]="slide.primary_text" [title]="slide.primary_text">
                  </div>
                </div>
                <div class="col-md-3 pr-4 pl-4">
                  <div class="t3-text-container">
                    <div class="t3 primary-text">
                      {{slide.primary_text}}
                    </div>
                    <div class="t3 secondary-text mt-4">
                    {{slide.secondary_text}}
                    </div>
                  </div>
                </div>
                <div class="col-md-1"></div>
              </div>
            </div>
          </ng-template>
          <ng-template  *ngIf="slide.template_id==7" carouselSlide [id]="slide.id">
            <div class="container">
              <div class="row">
                <div class="col-md-6 m-auto" >
                  <img class="t7-img" [src]="slide.image_json.images[0]" [alt]="slide.primary_text" [title]="slide.primary_text">
                </div>
                <div class="col-md-6 pl-3">
                  <div class="t3-text-container">
                    <div class="t3 primary-text">
                      {{slide.primary_text}}
                    </div>
                    <div class="t3 secondary-text mt-4">
                    {{slide.secondary_text}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template  *ngIf="slide.template_id==8" carouselSlide [id]="slide.id">
            <div class="container">
              <div class="row">
                <div class="col-md-6 pl-3">
                  <div class="t3-text-container">
                    <div class="t3 primary-text">
                      {{slide.primary_text}}
                    </div>
                    <div class="t3 secondary-text mt-4">
                    {{slide.secondary_text}}
                    </div>
                  </div>
                </div>
                <div class="col-md-6 m-auto" >
                  <img class="t7-img" [src]="slide.image_json.images[0]" [alt]="slide.primary_text" [title]="slide.primary_text">
                </div>
              </div>
            </div>
          </ng-template>
      </ng-container>
    </owl-carousel-o>
</div>

<ng-template #mobileView>
  <ng-container *ngFor="let slide of carouselArr; let i = index" class="font-raleway">
    <div *ngIf="slide.template_id == 1">
      <img [src]="slide.image_json.images[0]" class="w-100" style="position: relative; object-fit: cover;">
    </div>
    <div  *ngIf="slide.template_id==2 || slide.template_id==3 || slide.template_id==5 || slide.template_id==6 || slide.template_id==7 || slide.template_id==8">
      <img [src]="slide.image_json.images[0]" class="w-100" style="position: relative; object-fit: cover;">
        <div class="text-left pl-2 pt-3 pb-2 pr-2 font-weight-bold">
          <h6>
            <span class="font-weight-bold">{{slide.primary_text}} </span>
          </h6>
          <div class="pt-1 font-12">
            {{slide.secondary_text}}
          </div>
        </div>
    </div>

    <div  *ngIf="slide.template_id==4" class="font-raleway">
      <img [src]="slide.image_json.images[0]" class="w-100" style="position: relative; object-fit: cover;">

      <div class="text-center pt-2">
        <h6 class="font-raleway d-inline">
          {{slide.helper_text_1}}
        </h6>
        <h6 class="font-raleway d-inline">
          {{slide.helper_text_2}}
        </h6>
        <h6 class="font-raleway">
          <span class="font-weight-bold">
            {{slide.primary_text}}
          </span>
        </h6>
        <div class="font-weight-bold font-12">
        {{slide.secondary_text}}
        </div>
        <div class="p-3 useremailform">
          <form [formGroup]="userEmail" (ngSubmit)="onFormSubmit(userEmail)">
            <div class="form-group">
              <div *ngIf="isValidFormSubmitted && userEmailId"><h6> <span class="font-weight-normal">We will email you on</span></h6></div>
              <div *ngIf="isValidFormSubmitted && userEmailId"><h6> {{userEmailId}} </h6></div>
              <input *ngIf="!isValidFormSubmitted" [class.is-invalid]="email_id.invalid && email_id.touched" type="email" class="form-control" placeholder="Please enter your email address"
                formControlName="email_id" />
                <button *ngIf="!isValidFormSubmitted" class="submit-button-mobile"><svg class="arrow-design" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M6,13H14L10.5,16.5L11.92,17.92L17.84,12L11.92,6.08L10.5,7.5L14,11H6V13Z" />
                </svg></button>
              <div *ngIf="(email_id.invalid && email_id.touched) || email_id.dirty">
                <small *ngIf="email_id.errors?.required" class="text-danger">Primary email is required</small>
                <small *ngIf="email_id.errors?.pattern" class="text-danger">Please provide a valid email address</small>
              </div>
            </div>
          </form>
        </div>
        <div class="d-inline">
          <a href="https://www.instagram.com/osianamaworld/" target="_blank">
            <img src="./assets/IG Icon.png" class="icon-img d-inline" />
            <h6 class="d-inline pl-1 text-blue-colour font-14">@osianamaworld</h6>
          </a>
        </div>
        <div class="d-inline pl-3">
          <a href="" target="_blank">
            <img src="./assets/FB Icon.png" class="icon-img d-inline" />
            <h6 class="d-inline pl-1 text-blue-colour font-14">@osianamaworld</h6>
          </a>
        </div>
      </div>
    </div>
    <div class="mobile-image-separator">
    </div>
</ng-container>
</ng-template>