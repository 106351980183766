import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  opened = false;
  isVisionPage = false;

  constructor(private router: Router) { }

  ngOnInit(): void {
    setTimeout(()=>{
      if (this.router.url === '/vision') {
        this.isVisionPage = true
      }
    }, 100);
  }

  toggleSidebar()
  {
    this.opened = !this.opened
  }
}
