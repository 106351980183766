export const owlConfigs = {
    'HomepageCarouselOptions':
        {
            loop: true,
            mouseDrag: false,
            touchDrag: false,
            pullDrag: false,
            dots: false,
            navSpeed: 700,
            navText:  ['<i class=\'fa fa-chevron-left fa-10px\'></i>', '<i class=\'fa fa-chevron-right fa-10px\'></i>'],
            responsive: {
              960: {
                items: 1
              }
            },
            nav: true
        },
};
