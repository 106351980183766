import { environment } from './../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  
  // Define API
  apiBaseUrl = null;
  templateApi = null;
  accessToken
  constructor(private http: HttpClient) {}

  public init(environment) {
    this.apiBaseUrl = environment.apiBaseUrl;
    return this;
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }  

  getTemplate(templateId): Observable<[]> {
    this.templateApi = "/tempelates/"+ templateId +"?fields=*";
    return this.http.get(this.apiBaseUrl + this.templateApi, { headers: new HttpHeaders() }).pipe(map(data => {
      return (data['data']);
    }));
  }
  getTemplates(): Observable<[]> {
    this.templateApi = "/tempelates?limit=25&fields[]=content_alignment&fields[]=id&fields[]=is_image_fluid&fields[]=is_multiple_image&fields[]=status&sort=id&page=1&filter=%7B%22status%22:%7B%22_neq%22:%22archived%22%7D%7D";
    return this.http.get(this.apiBaseUrl + this.templateApi, { headers: new HttpHeaders() }).pipe(map(data => {
      return (data['data']);
    }));
  }
}
