import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ArticlesService {

  apiBaseUrl = null;
  carouselApi = null;
  constructor(private http: HttpClient) {
  }

  public init(environment) {
    this.apiBaseUrl = environment.apiBaseUrl;
    return this;
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }  

  getArticles(alias): Observable<[]> {
    this.carouselApi = "/articles?filter[alias][_eq]=" + alias;
    return this.http.get(this.apiBaseUrl + this.carouselApi, { headers: new HttpHeaders() }).pipe(map(data => {
      return (data['data']);
    }));
  }

}
