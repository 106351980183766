import { NgModule } from '@angular/core';
import { ArticleComponent } from './components/article/article.component';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [ CommonModule ],
    declarations: [ ArticleComponent ],
    providers : [],
    exports: [ ArticleComponent ]
  })
export class SharedModule {
}