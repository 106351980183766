import { SubsribersService } from './../subsribers.service';
import { TemplateService } from './../template.service';
import { environment } from './../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { owlConfigs } from './../config/owlConfig';
import { HompageCarouselService} from './../homepage-carousel.service' 
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {
  isValidFormSubmitted = false;
  userEmail = new FormGroup({
    email_id: new FormControl('',[
    Validators.required,
    Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")])
  });
  userEmailId;

  constructor(public homecarouselservice: HompageCarouselService, 
              public templateservice: TemplateService, 
              public SubsribersService: SubsribersService) { }

  carouselArr = [];
  formValue!: FormGroup;
  mobile;
  HomepageCarouselOptions;
  imagewidth;
  assetsUrl;
  
  ngOnInit(): void {
    this.assetsUrl = environment.assetsUrl;
    if (window.screen.width < 768) { // 768px portrait
      this.mobile = true;
    }

    this.homecarouselservice.init(environment).getCarousel().subscribe((response)=>{
      response.map(data => {
        let image
        if(data['image'] != null) {
          image = 
            {"images":[
              this.assetsUrl+data['image']
            ]}
        } else {
          image = data['image_json']
        }
        let tempObj = {
          "content_alignment": data['template']['content_alignment'],
          "text_alignment": data['template']['text_alignment'],
          "text_color": data['template']['text_color'],
          "is_multiple_image": data['template']['is_multiple_image'],
          "is_image_fluid": data['template']['is_image_fluid'],
          "primary_text": data['primary_text'],
          "secondary_text": data['secondary_text'],
          "image_json": image,
          "template_id": data['template']['id'],
          "helper_text_1": data['helper_text']!=null ? data['helper_text'][0] : null,
          "helper_text_2": data['helper_text']!=null ? data['helper_text'][1] : null,
        }
        this.carouselArr.push(tempObj)
      })
    });
    this.HomepageCarouselOptions = owlConfigs['HomepageCarouselOptions']
  }

  storeSubscriber(data)
  {
    this.SubsribersService.init(environment).storeSubscriber(data).subscribe((response)=>{
      if (response && response['status'] == 'published' ) {
        return true;
      } else {
        return false;
      }
    })
  }
  onFormSubmit() {
    if (this.userEmail.invalid) {
       return;
    }
    this.isValidFormSubmitted = true;
    this.userEmailId = this.userEmail.value.email_id;
    this.storeSubscriber(this.userEmail.value);
    this.userEmail.reset();
 }

  get email_id(){
    return this.userEmail.get('email_id')
  }

}
