<link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons">

<div id="mySidebar" class="sidebar">
  <a href="#"><i class="material-icons pl-2">home</i></a>
  <br>
  <a href="#"><i class="material-icons pl-2">window</i></a>
  <br>
  <a href="#"><i class="material-icons pl-2">event</i></a>
  <br>
  <a href="#"><i class="material-icons pl-2">fiber_manual_record</i></a>
</div>