import { environment } from './../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SubsribersService {
// Define API
apiBaseUrl = null;
subscribersApi = null;
constructor(private http: HttpClient) {
}

public init(environment) {
  this.apiBaseUrl = environment.apiBaseUrl;
  return this;
}

storeSubscriber(data): Observable<[]> {
  data.status = "published"
  this.subscribersApi = "/subscribers";
  return this.http.post(this.apiBaseUrl + this.subscribersApi, data,{ headers: new HttpHeaders() }).pipe(map(data => {
    return (data['data']);
  }));
  }
}
