import { Component, OnInit } from '@angular/core';
import { ArticlesService } from '../../index';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit {
  mobile = false;
  data;

  constructor(private articlesService: ArticlesService,
    private router: Router) {
    this.articlesService.init(environment);
  }

  ngOnInit(): void {
    if (window.screen.width < 768) { // 768px portrait
      this.mobile = true;
    }

    console.log(this.router.url.replace(/^\/|\/$/g, ''));

    this.articlesService.getArticles(this.router.url.replace(/^\/|\/$/g, '')).subscribe((response)=>{
      response.map(data => {
        console.log(data);
        if (data && data['data']) {
          this.data = data['data'];
        }
      })
    });

  }

}
