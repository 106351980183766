import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CheckmobileService {
  constructor() {}

    checkMobile() {
      if (window.screen.width < 768) {
        return true;
      } else {
        return false;
      }
    }
}
