import { environment } from './../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class HompageCarouselService {
  
  // Define API
  apiBaseUrl = null;
  carouselApi = null;
  constructor(private http: HttpClient) {
  }

  public init(environment) {
    this.apiBaseUrl = environment.apiBaseUrl;
    return this;
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }  

  getCarousel(): Observable<[]> {
    this.carouselApi = "/home_carousel?limit=25&fields[]=image&fields[]=image_json&fields[]=helper_text&fields[]=primary_text&fields[]=secondary_text&fields[]=status&fields[]=id&fields[]=template.*.*&sort=sort&page=1&filter=%7B%22status%22:%7B%22_neq%22:%22archived%22%7D%7D";
    return this.http.get(this.apiBaseUrl + this.carouselApi, { headers: new HttpHeaders() }).pipe(map(data => {
      return (data['data']);
    }));
  }

  storeCarousel(data): Observable<[]> {
    
    data.image_json = {
      "images":[data['image']]
    }
    data.helper_text = [data.helper_text_1,data.helper_text_2]
    data.status = "published"
    this.carouselApi = "/home_carousel";
    return this.http.post(this.apiBaseUrl + this.carouselApi, data,{ headers: new HttpHeaders() }).pipe(map(data => {
      return (data['data']);
    }));
  }

  deleteCarousel(data): Observable<[]> {
    this.carouselApi = "/home_carousel/";
    return this.http.delete(this.apiBaseUrl + this.carouselApi +  data,{ headers: new HttpHeaders() }).pipe(map(data => {
      return (data['data']);
    }));
  }
}